<template>
<Page id="tipsligan" title="Grupper">
      <Loader v-if="groupsloading" loadingtext="Hämtar grupper..."></Loader>
      <Alert v-else-if="groups.length <= 0" variant="info" >Det finns just nu inga grupper att visa</Alert>
      <div v-else>
            <b-container class="mt-5">
                  <div v-for="group in groups" :key="group.id" class="text-center">
                        <h3>{{group.name}}</h3>
                        <p class="font-italic" v-if="group.grouptype > 0">Gruppen är en slutspelsgrupp</p>
                        <p class="font-italic" v-else>Grupp {{group.shortname}} innehåller följande {{group.teams.length}} lag</p>
                        <div class="group-teams d-flex justify-content-center flex-wrap">
                              <div v-for="id in group.teams" :key="id">
                                    <Team :id="id"></Team>
                              </div>
                        </div>
                        <hr />
                  </div>
            </b-container>
      </div>
</Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const Team = () => import("@/components/groups/Team");
const Page = () => import("@/components/common/Page");

export default {
      name: "Groups",
      components: {
            Alert,
            Loader,
            Team,
            Page
      },
      data() {
            return {};
      },
      metaInfo: {
            title: "Grupper",
            meta: [{
                  name: "description",
                  content: "Grupper i turneringen",
            }, ],
      },
      computed: {
            groupsloading() {
                  return this.$store.getters["groups/groupsloading"];
            },            
            groups() {
                  var items = this.$store.getters["groups/groups"];
                  if (!items) items = [];
                  return items;
            }
      },
      methods: {
            userTournamentAccess(id) {
                  if (this.usertournaments && this.usertournaments[id]) {
                        return this.usertournaments[id].Access;
                  }
                  return false;
            },
            getTeam(id) {
                  return this.$functions.getTeam(id);
            }
      },
      mounted() {
            this.$store.dispatch("groups/getGroups");
      },
};
</script>
